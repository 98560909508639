import React, { FC, forwardRef } from 'react';
import { SvgIconProps } from '@material-ui/core';

import BestSvg from 'assets/svgs/Best.svg';
import ElectricitySvg from 'assets/svgs/Electricity.svg';
import GasSvg from 'assets/svgs/gas.svg';
import UploadSvg from 'assets/svgs/Upload.svg';
import ArrowDownSvg from 'assets/svgs/arrow-down.svg';
import BlueArrowSvg from 'assets/svgs/blue-arrow.svg';
import BackArrowSvg from 'assets/svgs/back-arrow.svg';
import BackArrowGray from 'assets/svgs/back-arrow-gray.svg';
import QuestionSvg from 'assets/svgs/question.svg';
import YellowPlusSvg from 'assets/svgs/yellow-plus.svg';
import RedCloseSvg from 'assets/svgs/redClose.svg';
import CheckSvg from 'assets/svgs/check.svg';
import CheckSuccessSvg from 'assets/svgs/check-success.svg';
import ErrorSvg from 'assets/svgs/error-circle.svg';
import ArrowUnsortedSvg from 'assets/svgs/sortable.svg';
import ArrowSortedDownSvg from 'assets/svgs/sort-arrow-down.svg';
import ArrowSortedUpSvg from 'assets/svgs/sort-arrow-up.svg';
import DashesSvg from 'assets/svgs/dashes.svg';
import NoFoundSvg from 'assets/svgs/no-found.svg';
import UploadFileSvg from 'assets/svgs/upload-file.svg';
import DeleteUploadSvg from 'assets/svgs/delete_upload.svg';
import UploadDoneSvg from 'assets/svgs/upload-done.svg';
import UploadFailedSvg from 'assets/svgs/upload_failed.svg';
import GrayFileSvg from 'assets/svgs/grayFile.svg';
import CheckMarkConfirmationFormSvg from 'assets/svgs/check-mark-confirmation-form.svg';
import NextArrowSvg from 'assets/svgs/next.svg';
import CloseModalSvg from 'assets/svgs/close-modal.svg';
import RetailCheckSvg from 'assets/svgs/retail-check.svg';
import RetailDetailsSvg from 'assets/svgs/retail-details.svg';
import RetailMapSvg from 'assets/svgs/retail-map.svg';
import RetailPenSvg from 'assets/svgs/retail-pen.svg';
import RetailDashesSvg from 'assets/svgs/retail-v-dashes.svg';
import SubmitMailSvg from 'assets/svgs/submit-mail.svg';
import ResidentialTypeSvg from 'assets/svgs/residential.svg';
import CommercialTypeSvg from 'assets/svgs/commercial.svg';
import SubmitArrowSvg from 'assets/svgs/submit-arrow-right.svg';
import FaqMagnifierIconSvg from 'assets/svgs/faq-magnifier.svg';
import FaqSearchIconSvg from 'assets/svgs/faq-search-icon.svg';
import FaqPlusIconSvg from 'assets/svgs/faq-plus-icon.svg';
import FaqPointIconSvg from 'assets/svgs/faq-point-icon.svg';
import FaqDashIconSvg from 'assets/svgs/faq-dash-icon.svg';
import OurPartnersHandshakeIconSvg from 'assets/svgs/our-partners-handshake-icon.svg';
import MobileFilterSvg from 'assets/svgs/greenFilter.svg';
import ContactCheckIconSvg from 'assets/svgs/contact-check-icon.svg';
import PlansExclamationPointSvg from 'assets/svgs/plans-exclamation-point.svg';
import FaqIconSvg from 'assets/svgs/faq-icon-1.svg';
import QuestionGreySvg from 'assets/svgs/question-grey.svg';
import CloseRoundSvg from 'assets/svgs/close-round.svg';
import CookiesWidgetIconSvg from 'assets/svgs/cookies-widget-icon.svg';
import SortShortIconSvg from 'assets/svgs/short.svg';
import SortLongIconSvg from 'assets/svgs/long.svg';
import SortLowestIconSvg from 'assets/svgs/lowest.svg';
import SortHighestIconSvg from 'assets/svgs/highest.svg';
import SortBestSavingsIconSvg from 'assets/svgs/bestSavings.svg';
import SortWorstSavingsIconSvg from 'assets/svgs/worstSavings.svg';
import SortSupplierAZIconSvg from 'assets/svgs/supplierAZorder.svg';
import SortSupplierZAIconSvg from 'assets/svgs/supplierZAorder.svg';
import NavArrowDownIconSvg from 'assets/svgs/nav-arrow-down-icon.svg';
import ArrowLinkRightIconSvg from 'assets/svgs/arrow-link-right.svg';
import AppArrowIconSvg from 'assets/svgs/common/app-arrow.svg';
import AppDismissIconSvg from 'assets/svgs/common/app-dismiss.svg';
import AppPictureIconSvg from 'assets/svgs/common/app-picture.svg';
import PdfIconSvg from 'assets/svgs/pdf.svg';

// Blog icons
import CopyLinkIconSvg from 'assets/svgs/blog/akar-icons_link-chain.svg';
import TwitterIconSvg from 'assets/svgs/blog/ant-design_twitter-outlined.svg';
import FacebookIconSvg from 'assets/svgs/blog/cib_facebook-f.svg';
import LinkedInIconSvg from 'assets/svgs/blog/Vector.svg';

export const LinkedInIcon: FC<SvgIconProps> = forwardRef((props, ref) => (
  <LinkedInIconSvg ref={ref} {...props} />
));

export const TwitterIcon: FC<SvgIconProps> = forwardRef((props, ref) => (
  <TwitterIconSvg ref={ref} {...props} />
));

export const FacebookIcon: FC<SvgIconProps> = forwardRef((props, ref) => (
  <FacebookIconSvg ref={ref} {...props} />
));

export const CopyLinkIcon: FC<SvgIconProps> = forwardRef((props, ref) => (
  <CopyLinkIconSvg ref={ref} {...props} />
));

// End Blog icons

export const BestIcon: FC<SvgIconProps> = forwardRef((props, ref) => (
  <BestSvg ref={ref} {...props} />
));
export const ElectricityIcon: FC<SvgIconProps> = forwardRef((props, ref) => (
  <ElectricitySvg ref={ref} {...props} />
));
export const GasIcon: FC<SvgIconProps> = forwardRef((props, ref) => (
  <GasSvg ref={ref} {...props} />
));
export const UploadIcon: FC<SvgIconProps> = forwardRef((props, ref) => (
  <UploadSvg ref={ref} {...props} />
));
export const ArrowDownIcon: FC<SvgIconProps> = forwardRef((props, ref) => (
  <ArrowDownSvg ref={ref} {...props} />
));
export const BlueArrowIcon: FC<SvgIconProps> = forwardRef((props, ref) => (
  <BlueArrowSvg ref={ref} {...props} />
));
export const BackArrowIcon: FC<SvgIconProps> = forwardRef((props, ref) => (
  <BackArrowSvg ref={ref} {...props} />
));
export const BackArrowGrayIcon: FC<SvgIconProps> = forwardRef((props, ref) => (
  <BackArrowGray ref={ref} {...props} />
));
export const QuestionIcon: FC<SvgIconProps> = forwardRef((props, ref) => (
  <QuestionSvg ref={ref} {...props} />
));
export const YellowPlusIcon: FC<SvgIconProps> = forwardRef((props, ref) => (
  <YellowPlusSvg ref={ref} {...props} />
));
export const RedCloseIcon: FC<SvgIconProps> = forwardRef((props, ref) => (
  <RedCloseSvg ref={ref} {...props} />
));
export const ErrorIcon: FC<SvgIconProps> = forwardRef((props, ref) => (
  <ErrorSvg ref={ref} {...props} />
));

export const CheckIcon: FC<SvgIconProps> = forwardRef((props, ref) => (
  <CheckSvg ref={ref} {...props} />
));

export const CheckSuccessIcon: FC<SvgIconProps> = forwardRef((props, ref) => (
  <CheckSuccessSvg ref={ref} {...props} />
));

export const ArrowUnsortedIcon: FC<SvgIconProps> = forwardRef((props, ref) => (
  <ArrowUnsortedSvg ref={ref} {...props} />
));

export const ArrowSortedDownIcon: FC<SvgIconProps> = forwardRef((props, ref) => (
  <ArrowSortedDownSvg ref={ref} {...props} />
));

export const ArrowSortedUpIcon: FC<SvgIconProps> = forwardRef((props, ref) => (
  <ArrowSortedUpSvg ref={ref} {...props} />
));

export const DashesIcon: FC<SvgIconProps> = forwardRef((props, ref) => (
  <DashesSvg ref={ref} {...props} />
));

export const NoFoundIcon: FC<SvgIconProps> = forwardRef((props, ref) => (
  <NoFoundSvg ref={ref} {...props} />
));

export const UploadFile: FC<SvgIconProps> = forwardRef((props, ref) => (
  <UploadFileSvg ref={ref} {...props} />
));

export const DeleteUpload: FC<SvgIconProps> = forwardRef((props, ref) => (
  <DeleteUploadSvg ref={ref} {...props} />
));

export const UploadDoneIcon: FC<SvgIconProps> = forwardRef((props, ref) => (
  <UploadDoneSvg ref={ref} {...props} />
));

export const UploadFailedIcon: FC<SvgIconProps> = forwardRef((props, ref) => (
  <UploadFailedSvg ref={ref} {...props} />
));

export const GrayFile: FC<SvgIconProps> = forwardRef((props, ref) => (
  <GrayFileSvg ref={ref} {...props} />
));

export const CheckMarkConfirmationForm: FC<SvgIconProps> = forwardRef((props, ref) => (
  <CheckMarkConfirmationFormSvg ref={ref} {...props} />
));

export const NextArrow: FC<SvgIconProps> = forwardRef((props, ref) => (
  <NextArrowSvg ref={ref} {...props} />
));

export const CloseModal: FC<SvgIconProps> = forwardRef((props, ref) => (
  <CloseModalSvg ref={ref} {...props} />
));

export const RetailCheck: FC<SvgIconProps> = forwardRef((props, ref) => (
  <RetailCheckSvg ref={ref} {...props} />
));

export const RetailDetails: FC<SvgIconProps> = forwardRef((props, ref) => (
  <RetailDetailsSvg ref={ref} {...props} />
));

export const RetailMap: FC<SvgIconProps> = forwardRef((props, ref) => (
  <RetailMapSvg ref={ref} {...props} />
));

export const RetailPen: FC<SvgIconProps> = forwardRef((props, ref) => (
  <RetailPenSvg ref={ref} {...props} />
));

export const RetailDashes: FC<SvgIconProps> = forwardRef((props, ref) => (
  <RetailDashesSvg ref={ref} {...props} />
));

export const SubmitMailIcon: FC<SvgIconProps> = forwardRef((props, ref) => (
  <SubmitMailSvg ref={ref} {...props} />
));

export const ResidentialType: FC<SvgIconProps> = forwardRef((props, ref) => (
  <ResidentialTypeSvg ref={ref} {...props} />
));

export const CommercialType: FC<SvgIconProps> = forwardRef((props, ref) => (
  <CommercialTypeSvg ref={ref} {...props} />
));

export const FaqMagnifierIcon: FC<SvgIconProps> = forwardRef((props, ref) => (
  <FaqMagnifierIconSvg ref={ref} {...props} />
));

export const FaqSearchIcon: FC<SvgIconProps> = forwardRef((props, ref) => (
  <FaqSearchIconSvg ref={ref} {...props} />
));

export const FaqPlusIcon: FC<SvgIconProps> = forwardRef((props, ref) => (
  <FaqPlusIconSvg ref={ref} {...props} />
));

export const FaqPointIcon: FC<SvgIconProps> = forwardRef((props, ref) => (
  <FaqPointIconSvg ref={ref} {...props} />
));

export const FaqDashIcon: FC<SvgIconProps> = forwardRef((props, ref) => (
  <FaqDashIconSvg ref={ref} {...props} />
));

export const SubmitArrow: FC<SvgIconProps> = forwardRef((props, ref) => (
  <SubmitArrowSvg ref={ref} {...props} />
));

export const OurPartnersHandshakeIcon: FC<SvgIconProps> = forwardRef((props, ref) => (
  <OurPartnersHandshakeIconSvg ref={ref} {...props} />
));

export const MobileFilterIcon: FC<SvgIconProps> = forwardRef((props, ref) => (
  <MobileFilterSvg ref={ref} {...props} />
));

export const ContactCheckIcon: FC<SvgIconProps> = forwardRef((props, ref) => (
  <ContactCheckIconSvg ref={ref} {...props} />
));

export const PlansExclamationPoint: FC<SvgIconProps> = forwardRef((props, ref) => (
  <PlansExclamationPointSvg
    ref={ref}
    style={{ marginLeft: 6, top: 3, position: 'relative' }}
    {...props}
  />
));

export const FaqIcon: FC<SvgIconProps> = forwardRef((props, ref) => (
  <FaqIconSvg ref={ref} {...props} />
));

export const QuestionGreyIcon: FC<SvgIconProps> = forwardRef((props, ref) => (
  <QuestionGreySvg ref={ref} {...props} />
));

export const CloseRoundIcon: FC<SvgIconProps> = forwardRef((props, ref) => (
  <CloseRoundSvg ref={ref} {...props} />
));

export const CookiesWidgetIcon: FC<SvgIconProps> = forwardRef((props, ref) => (
  <CookiesWidgetIconSvg ref={ref} {...props} />
));

export const SortShortIcon: FC<SvgIconProps> = forwardRef((props, ref) => (
  <SortShortIconSvg ref={ref} {...props} />
));

export const SortLongIcon: FC<SvgIconProps> = forwardRef((props, ref) => (
  <SortLongIconSvg ref={ref} {...props} />
));

export const SortHighestIcon: FC<SvgIconProps> = forwardRef((props, ref) => (
  <SortHighestIconSvg ref={ref} {...props} />
));

export const SortLowestIcon: FC<SvgIconProps> = forwardRef((props, ref) => (
  <SortLowestIconSvg ref={ref} {...props} />
));

export const SortBestSavingsIcon: FC<SvgIconProps> = forwardRef((props, ref) => (
  <SortBestSavingsIconSvg ref={ref} {...props} />
));

export const SortWorstSavingsIcon: FC<SvgIconProps> = forwardRef((props, ref) => (
  <SortWorstSavingsIconSvg ref={ref} {...props} />
));

export const SortSupplierAZIconIcon: FC<SvgIconProps> = forwardRef((props, ref) => (
  <SortSupplierAZIconSvg ref={ref} {...props} />
));

export const SortSupplierZAIcon: FC<SvgIconProps> = forwardRef((props, ref) => (
  <SortSupplierZAIconSvg ref={ref} {...props} />
));

export const NavArrowDownIcon: FC<SvgIconProps> = forwardRef((props, ref) => (
  <NavArrowDownIconSvg ref={ref} {...props} />
));

export const AppArrowIcon: FC<SvgIconProps> = forwardRef((props, ref) => (
  <AppArrowIconSvg ref={ref} {...props} />
));

export const AppDismissIcon: FC<SvgIconProps> = forwardRef((props, ref) => (
  <AppDismissIconSvg ref={ref} {...props} />
));

export const AppPictureIcon: FC<SvgIconProps> = forwardRef((props, ref) => (
  <AppPictureIconSvg ref={ref} {...props} />
));

export const ArrowLinkRightIcon: FC<SvgIconProps> = forwardRef((props, ref) => (
  <ArrowLinkRightIconSvg ref={ref} {...props} />
));

export const PdfIcon: FC<SvgIconProps> = forwardRef((props, ref) => (
  <PdfIconSvg ref={ref} {...props} />
));
