import React, { FC } from 'react';
import { CircularProgress, makeStyles } from '@material-ui/core';
import clsx from 'clsx';
import { Theme } from '@material-ui/core/styles';

interface ProgressSpinnerProps {
  minHeight?: number;
  className?: string;
}

const useStyles = makeStyles((theme: Theme) => ({
  root: () => ({
    display: 'flex',
    flex: 1,
    justifyContent: 'center',
    alignItems: 'center',
    padding: '5px 24px',
    color: '#ffffff',
    minWidth: 197.09,
    backgroundColor: theme.palette.primary.light,
    borderRadius: 6,
  }),
}));

const ProgressSpinner: FC<ProgressSpinnerProps> = ({ className = '' }) => {
  const classes = useStyles();

  return (
    <div className={clsx(classes.root, className)}>
      <CircularProgress color={'inherit'} />
    </div>
  );
};

export default ProgressSpinner;
