import React, { FC, ReactNode } from 'react';
import Link from 'next/link'

interface PageLinkProps {
  href: string;
  children: ReactNode;
  className?: string;
  id?: string;
  rel?: string;
  target?: string;
  style?: Record<string, any>;
}

const PageLink: FC<PageLinkProps> = (props) => {
  const { href, ...aProps } = props;
  const fixedHref = href.includes('undefined') ? '/' : href;

  return (
    <Link href={fixedHref} prefetch={false}>
      <a {...aProps} />
    </Link>
  );
};

export default PageLink;
