import { useCallback, useContext } from "react";
import { i18nContext, I18nValue } from "evcharging/providers/I18nProvider";

const useI18n = () => {
  const { dictionary = {}, links = {} } = useContext<I18nValue>(i18nContext);

  const t = useCallback(
    (id: string, params?: Record<string, string | number>): string => {
      const translation = dictionary[id] || id;

      // Handle dynamic text replacement
      if (params) {
        return translation.replace(/{([^}]+)}/g, (match, param) => {
          return `${params[param]}` && `${params[param] || match}`;
        });
      }

      return translation;
    },
    [dictionary],
  );

  return { t, links };
};

export default useI18n;
