import React, { FC, useState } from 'react';
import { Box, Fade, makeStyles, Theme, Typography } from '@material-ui/core';
import { NavArrowDownIcon } from 'assets/svgs';
import clsx from 'clsx';
import useI18n from "evcharging/hooks/useI18n";
import { PageLink } from "components/common/atoms";

interface NavLinkDropdownProps {
  label: string;
  title: string;
  links: Array<{
    href: string;
    label: string;
    onClick?: () => void;
  }>;
}

const useStyles = makeStyles((theme: Theme) => ({
  dropdownLink: {
    cursor: 'pointer',
    display: 'flex',
    alignItems: 'center',
    color: 'inherit',
    fontWeight: 600,
    fontSize: '16px !important',
    transition: 'all 300ms',
    [theme.breakpoints.up('lg')]: {
      fontSize: '18px !important',
    },
    '& svg': {
      marginLeft: theme.spacing(1),
      marginTop: 3,
    },
  },
  dropDownList: {
    position: 'absolute',
    backgroundColor: '#fff',
    zIndex: 1000,
    top: 15,
    left: '98%',
    boxShadow: '0px 4px 37px rgba(0, 0, 0, 0.1)',
    display: 'flex',
    flexDirection: 'column',
    padding: theme.spacing(5, 5, 2, 3),
    borderRadius: theme.spacing(2),
    marginTop: theme.spacing(4),
    whiteSpace: 'nowrap',
    transform: 'translate(-50%, 0)',
  },
  link: {
    color: '#333',
    fontSize: 16,
    fontWeight: 600,
    marginBottom: theme.spacing(3),
    '&:hover': {
      color: theme.palette.primary.main,
    },
  },
  title: {
    color: theme.palette.primary.main,
    fontSize: 12,
    fontWeight: 600,
    marginBottom: theme.spacing(3),
  },
  showMoreIcon: {
    transition: 'all 200ms',
  },
  rotateIcon: {
    transform: 'rotate(-180deg)',
  },
  dropdownLabel: {
    fontSize: 18,
    '&:hover': {
      textDecoration: 'underline',
    },
  },
}));

const NavLinkDropdownDesktop: FC<NavLinkDropdownProps> = (props) => {
  const { label, links, title } = props;

  const { t } = useI18n();

  const [showMore, setShowMore] = useState<boolean>(false);

  const toggleShowMore = () => setShowMore(true);
  const toggleShowLess = () => setShowMore(false);
  const classes = useStyles();

  return (
    <Box onMouseEnter={toggleShowMore} onMouseLeave={toggleShowLess} position="relative">
      <Typography variant="body1" className={classes.dropdownLink}>
        {label}
        <NavArrowDownIcon className={clsx(classes.showMoreIcon, showMore && classes.rotateIcon)} />
      </Typography>
      <Fade in={showMore}>
        <Box className={classes.dropDownList}>
          {/*<div className={classes.title}>{title}</div>*/}
          {links.map((link, index) => (
            <PageLink
              href={link.href}
              key={index}
              aria-label={link.label}
              className={classes.link}
            >
              {t(link.label)}
            </PageLink>
          ))}
        </Box>
      </Fade>
    </Box>
  );
};

export default NavLinkDropdownDesktop;
