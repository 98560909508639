// Internal
export const EVC_BASE_URL = '/evcharging';

export const HOME_URL = '/';
export const EV_CALCULATOR_URL = `${EVC_BASE_URL}/ev-calculator`;
export const EV_CHARGER_URL = `${EVC_BASE_URL}/ev-charger`;
export const EV_REBATES_URL = `${EVC_BASE_URL}/ev-rebates`;
export const EV_SOFTWARE_URL = `${EVC_BASE_URL}/ev-software`;
export const OCPP_URL = `${EVC_BASE_URL}/ocpp`;
export const OPEN_ADR_URL = `${EVC_BASE_URL}/open-adr`;
export const EV_CONSULTATION_URL = `${EVC_BASE_URL}/consultation`;
export const EV_TAX_CREDIT_URL = `${EVC_BASE_URL}/tax-credit`;
export const EV_PROJECTS_URL = `${EVC_BASE_URL}/projects`;

export const ABOUT_US_URL = '/about-us';
export const CONTACT_US_URL = '/contact-us';
export const PRIVACY_POLICY_URL = '/privacy';
export const TERMS_AND_CONDITIONS_URL = '/terms';

export const RSS_URL = `/rss.xml`;
export const BLOG_URL = `/blog`;
export const BLOG_CATEGORY_URL = (link: string) => `/blog/${link}`;

export const NOT_FOUND_URL = `/404`;

// Anchors
export const CONTACT_US_ID = `contact-us`;
export const CONTACT_US_ANCHOR = `${EVC_BASE_URL}#${CONTACT_US_ID}`;
