import React, { FC, useState } from 'react';
import { Box, Collapse, makeStyles, Theme, Typography } from '@material-ui/core';
import { NavArrowDownIcon } from 'assets/svgs';
import clsx from 'clsx';
import useI18n from "evcharging/hooks/useI18n";
import { PageLink } from "components/common/atoms";

interface NavLinkDropdownProps {
  label: string;
  links: Array<{
    href: string;
    label: string;
    onClick?: () => void;
  }>;
  color?: string;
}

const useStyles = makeStyles((theme: Theme) => ({
  dropdownLink: {
    cursor: 'pointer',
    display: 'flex',
    alignItems: 'center',
    transition: 'all 300ms',
    color: (props: NavLinkDropdownProps) => props.color,
  },
  isLink: {
    color: (props: NavLinkDropdownProps) => props.color,
    fontWeight: 600,
    fontSize: '16px !important',
    margin: `15px !important`,
    '& svg': {
      marginLeft: theme.spacing(2),
      marginTop: 3,
      '& path': {
        fill: (props: NavLinkDropdownProps) => props.color,
      },
    },
  },
  dropDownList: {
    display: 'flex',
    flexDirection: 'column',
    margin: theme.spacing(2, 3, 0, 5),
  },
  link: {
    color: (props: NavLinkDropdownProps) => props.color,
    fontWeight: 400,
    fontSize: 16,
    marginBottom: theme.spacing(3),
  },
  showMoreIcon: {
    transition: 'all 200ms',
  },
  rotateIcon: {
    transform: 'rotate(-180deg)',
  },
  dropdownLabel: {
    fontSize: 18,
    '&:hover': {
      textDecoration: 'underline',
    },
  },
}));

const NavLinkDropdownMobile: FC<NavLinkDropdownProps> = (props) => {
  const { label, links, color = '#fff' } = props;

  const { t } = useI18n();

  const [showMore, setShowMore] = useState<boolean>(false);
  const toggleMenu = () => setShowMore((prevState) => !prevState);
  const classes = useStyles({ color } as NavLinkDropdownProps);

  return (
    <Box onClick={toggleMenu} position="relative">
      <Typography variant="body1" className={clsx(classes.dropdownLink, classes.isLink)}>
        {label}
        <NavArrowDownIcon className={clsx(classes.showMoreIcon, showMore && classes.rotateIcon)} />
      </Typography>
      <Collapse in={showMore}>
        <Box className={classes.dropDownList}>
          {links.map((link) => (
            <PageLink
              key={link.href}
              href={link.href}
              aria-label={link.label}
              className={classes.link}
            >
              {t(link.label)}
            </PageLink>
          ))}
        </Box>
      </Collapse>
    </Box>
  );
};

export default NavLinkDropdownMobile;
